



*{
  
  /* border: 1px solid red; */
}

body{
  background-color: #F4EBD9;
}
.myText{
  /* font-family: Georgia; */
  /* border: red solid 1px; */
  font-size: 16pt;
  text-align: left;
  /* padding-top: 1%; */
  padding: 1% 0 1% 0;
  width: 100%;
  max-width: 800px;
  /* min-width: 300px; */
  /* padding-left: 20%;
  padding-right: 20%; */
margin: auto;

 /* margin-bottom: .5%; */
}



.logo{

  padding: 0px 0px 0px 0px;

}


.topdiv{
  font-family: Didot ;
  /* font-family: 'Gill Sans';
  font-family: 'optima'; */
  height: 110px;
  width: 100%;

  margin: auto;
  /* margin-bottom: 20px; */
  color: white;
  color: #F4EBD9;



  /* color: #D2D7DF; */
  

  /* border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px; */

  /* border: black solid 4px; */
  /* background-color: rgb(17, 50, 112); */
  background-color: #2c898c;

  /* outline:black solid 4px; */
  text-align: center;

}

.navstyle{
  font-family: Georgia, 'Times New Roman', Times, serif;
  position: sticky;
  z-index: 1;
  /* justify-content: center; */
  /* font-weight: bold; */

  font-size:20px;
  
  /* height: 50px; */
/* min-width: 1000px; */
  top: 0;
  width: 100%;

}




.navitem{
  margin: 0px 40px 0px 0px;
  

}

.centerDiv{
  padding: 50px;
  margin: auto;
  width: 50%;
  text-align: center;
}
.center{

  padding: 70px;
  margin: auto;
  width: 90%;
  text-align: center;
}

.row{
  border: 2px solid black;
  border-radius: 25px;
  height: 650px;
  display: inline-block;
  vertical-align: top;
  margin:auto;
  width: 200pt;
  margin-right: 30px;

  padding: 0 20px 0 20px;
  }




ul {
  display: table;
  margin: auto;
  text-align: left;
  /* list-style-position: inside; */
}

.subText{
  font-size: 30px;
  color: grey;
  text-align: center;
  padding-top: 50px;
  padding-left: 300px;
  padding-right: 300px;

}

.hickFaith{

  /* border-radius: 25px;
  border: 2px solid #2c898c;  */

  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;


  min-width: 350px;
  /* overflow: hidden; */
}



.multpic{
  /* border: blue solid 3px; */
  padding: 0 0 1.5% 0;
  text-align: center;


  margin: auto;
  width: 100%;

}

.rowpic{
vertical-align: middle;
  display: inline-block;
  /* border: red solid 3px; */
  padding: 10px 0px 10 0px;
  width: 50%;
  min-width: 360px;


  /* height: 100%;
  vertical-align: middle; */


  /* min-height: 300px; */
  /* border: red solid 3px; */
  margin: auto;
  margin-top: 29px;

  text-align: center;
  /* overflow: hidden; */
}

li span {
  position: relative;
  left: -10px;
}


.pic{

  /* max-width: 100%;
  max-height: 100%; */
  min-height: 250px;
  height: 35vw;
  width: 95%;
  object-fit: cover;
  /* object-position: 0 0 100%; */

  /* border-radius: 25px;
  border: 2px solid black; */

  margin:auto;
  /* position: relative; */
top: -4vw;
  /* width: 100%; */

}


.allServices{
  /* color: #2b5153; */
  color: #482728;
  /* color: #443730; */
  /* color: black; */

}



.servicediv{
  /* box-sizing: border-box; */
  margin: auto;
  text-align: center;
  padding-left: 1vw;
  padding-right: 1vw;
  /* border: 8px solid green;  */
  /* display: inline-block; */

}


.serviceImg{
  width: 100%;
  height: 100%; 
  object-fit: cover;
  


}

.servicePicDiv{ 
  /* box-sizing: border-box; */
   vertical-align: top;
  display: inline-block;
  /* border: 4px solid black; */
  /* text-align: center; */
  width: 50%;
  max-width: 600px;
  min-width: 360px;
  /* width: 40vw; */
  /* width: 622px; */
  /* height: 350px; */
  /* height: 40vw; */

  

}



.servicetextdiv{
  max-width: 600px;
text-align: left;
font-size: 14pt;
  /* box-sizing: border-box; */
  display: inline-block;
  padding: 0px 2vw 0px 2vw;
  /* border: 2px solid blue; */
  width: 50%;
  min-width: 360px;


  
}


.myform{

  text-align: center;
  padding-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
  /* border: red solid 2px; */
  font-size: 14pt;
}

/* .blackbox{
  overflow: hidden;
  background-color: black;
} */

.formdiv{
display: block;
 width: 100%;
  max-width: 600px;


  text-align: left;
  /* width: 96%; */
  /* border: 2px solid black; */
  margin: auto;
  /* width: 350px; */
  /* display: inline-block; */
  /* float: right; */
}


.inputtext{
  /* font-size: 12pt; */
  width: 100%;
  max-width: 600px;
  /* resize: auto; */
  display: inline-block;
}

.mybutton{
  margin: auto;
  padding: 0px;

  text-align: center;
  
  font-size: 14pt;

   display: block;

    width: 90px;
    padding-top: 7px;
    padding-bottom: 7px;
    /* height: 40px; */
    background-color: #56A3A6;
/* color: #2b5153; */

    border: 0;
    box-shadow: none;

}

.mybutton:hover {
  background-color: rgb(78, 147, 150)
  /* background-color:#3f7779 !important; */
  /* filter: brightness(0.90); */
}
.mybutton:focus,.mybutton:active {
  background-color: rgb(78, 147, 150) !important;
  box-shadow: none !important;

}

.hometext1{
  font-size: calc(22px + .84vw);
  /* font-family: 'Courier New', Courier, monospace; */
  font-family: Didot;
  /* color: pink; */
  color: #F4EBD9;
  /* position: absolute; */
}

.hometext1div{
  display: inline-block;
  min-width: 190px;
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
left: 2%;
  margin-top: calc(50px + 2.5vw);
  border-radius: 25px;
  background-color: rgb(78, 147, 150, .7);
  position: absolute;

  width: 30%;
  /* border: 3px red solid; */
}



.homepicdiv{
  /* border: red solid 1px; */
  height: 35vw;
  width: 100%;
  min-height: 375px;
  overflow: hidden;
  /* min-width: 650px; */
}
.homepic{
  max-height: 100%;
  min-width: 570px;
  min-height: 375px;
  /* max-width: 100%; */
  /* object-position: 70px 50px; */
  object-fit: cover;

}

.leadtraindiv{
  margin-top: 30px;
  /* padding: 0 2% 0 2%; */

/* border: red solid 3px; */
display: inline-block;
width: 40%;
max-width: 500px;
min-width: 360px;
 /* width: 500px; */
/* max-height: 525px; */


/* height: 525px; */
vertical-align: top;

/* color:#2c898c; */
/* color: #2b5153; */
/* color: #443730; */
color: #482728;

}

.leadtrainimg{
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

/* .textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
} */